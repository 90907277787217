import { withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Button as AntdButton, Col, Dropdown, Menu, Modal, Row, Switch } from "antd";
import cx from "classnames";
import WarningModal from "components/simpify-modals/warning";
import config from "config";
import _ from "lodash";
import {
    allowEditPaymemnt,
    getDeliveryStatusNumber,
    ORDER_DELIVERY_STATUS_ACTIONS,
    ORDER_DETAIL_ACTIONS,
} from "pages/order-history/helper";
import { handlePrintReceipt } from "pages/order-history/order-print/order-detail-print-view";
import {
    BULK_UPDATE_API_TYPE,
    BULK_UPDATE_STATUES,
} from "pages/order-history/page-components/order-list-controllers/bulk-update-controller/bulk-update-process-modal";
import { orderPaymentDialog } from "pages/order-history/page-components/order-payment-dialog";
import { Component } from "react";
import { IoMdArrowDropup } from "react-icons/io";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import _actions from "slices/live-order";
import _actionsOrder from "slices/order-history";
import helper, { getDeliveryServiceProvider, showMarkAsPaidOrUnpaid } from "util/helper";
import LiveOrder from "util/model/live-order";
import {
    cancelDoordashDelivery,
    createDoordashDelivery,
    fetchDeliveryFeeEstimate,
    getDeliveryURL,
} from "../../../../services/store";
import { isGroupedOrder, isRestaurant, requireDineInDueTime } from "../../helper";
import CancelDeliveryModal from "../cancel-delivery-modal/index";
import CancelModal from "../cancel-modal";
import DoordashModal from "../delivery-modal/index";
import ProcessingModal from "../processing-modal";
import DoordashTrackingModal from "../doordash-tracking-modal";
//import { setDoordashDeliveryDetails } from "slices/store";

const DOORDASH = "doordash";
const DELIVERING_STATUS = 200;
const ENABLED = 1;
const ACCEPTED_DELIVERY_STATUS = 115;
const ENABLE_DELIVERY = "2";
const THIRD_PARTY_ENABLE = "1";

//red
const StyledButton1 = withStyles({
    root: {
        lineHeight: 1.3,
        fontSize: 14,
        paddingLeft: 5,
        paddingRight: 5,
        textTransform: "initial",
        backgroundColor: "#f50057",
        color: "white",
        "&:hover": {
            backgroundColor: "#f50057",
            borderColor: "#0062cc",
            boxShadow: "none",
        },
        "&:active": {
            boxShadow: "none",
            backgroundColor: "#f50057",
            borderColor: "#005cbf",
        },
    },
})(Button);

//green
const StyledButton2 = withStyles({
    root: {
        lineHeight: 1.3,
        fontSize: 14,
        paddingLeft: 5,
        paddingRight: 5,
        textTransform: "initial",
        backgroundColor: "#52c41a",
        color: "white",
        "&:hover": {
            backgroundColor: "#33691e",
            borderColor: "#0062cc",
            boxShadow: "none",
        },
        "&:active": {
            boxShadow: "none",
            backgroundColor: "#33691e",
        },
    },
})(Button);

//blue
const StyledButton3 = withStyles({
    root: {
        lineHeight: 1.3,
        fontSize: 14,
        paddingLeft: 5,
        paddingRight: 5,
        textTransform: "initial",
        backgroundColor: "#3898c8",
        color: "white",
        "&:hover": {
            backgroundColor: "#2a7ea8",
            borderColor: "#2baced",
            boxShadow: "none",
        },
        "&:active": {
            boxShadow: "none",
            backgroundColor: "#2a7ea8",
        },
    },
})(Button);

class HeadRowButons extends Component {
    state = {};

    getListStyle = () => _.get(this.props, "state.listStyle", "list");

    str = (values) => this.props.intl.formatMessage(values);

    order = () => (this.props.order ? this.props.order : {});

    getOrderId = () => _.get(this.order(), "ord_id");

    store = () => (this.props.orderStore ? this.props.orderStore : {});

    getOrderDeliveryStatus = () => String(getDeliveryStatusNumber(this.order()));

    getOrderStatus = () => _.get(this.order(), "ord_st", 0);

    getDeliveryTaskId = () => _.get(this.order(), "delivery_id", "");

    getPayStatus = () => _.get(this.order(), "pay_status", config.PAY_STATUS_TO_NUMERIC.default);

    isListStyle = () => this.getListStyle() === "list";

    isThirdPartyCourierCollectedPackage = () => {
        const delivery_status = this.order()?.delivery_status;
        // check this delivery order has a third party courier like door dash or ubereats
        const delivery_partner_name = this.order()?.delivery_partner_name;
        return (
            delivery_partner_name &&
            delivery_status >= config.ORDER_DELIVERY_STATUS_MAPPING_TO_NUMERIC.courier_collected_the_package
        );
    };

    getButtons = () => {
        // "100": "order_canceled",
        // "110": "pending_for_store_confirmation",
        // "112": "pending_for_customer_confirmation",
        // "115": "store_accepted",
        // "120": "store_is_preparing",
        // "130": "ready_for_pick_up",
        // "131": "ready_for_courier_pick_up",
        // "201": "rejected_by_courier",
        // "210": "courier_assigned",
        // "220": "courier_accepted",
        // "230": "courier_heading_to_store",
        // "240": "courier_arrive_at_the_store",
        // "250": "courier_collected_the_package",
        // "260": "courier_heading_to_customer",
        // "270": "courier_arrive_at_customer",
        // "280": "delivered_order"

        // "delivery": "1",
        // "pickup": "2",
        // "eatin": "3",
        // "flatrate": "4",
        // "freeShipping": "5"
        // "instantCheckout": "6"

        //button map:
        //key: delivery status-order type
        //value: the buttons show in this case
        const delivery_partner_name = this.order()?.delivery_partner_name;
        const buttonMap = {
            "110-1,2,3,4,5,6": [this.renderRejectButton, this.renderAcceptButton],
            "112-1,2,3,4,5,6": [this.renderRejectButton, this.renderFinalizeButton],
            "115-1,2": [this.renderStartPreparingButton, this.renderPickupButton, this.renderCompleteButton],
            "115-3": [this.renderStartPreparingButton, this.renderServeButton, this.renderCompleteButton],
            "115-4,5": [this.renderStartPreparingButton, this.renderCompleteButton],
            "120-2": [this.renderPickupButton, this.renderCompleteButton],
            "120-1":
                getDeliveryServiceProvider(this.props.store) ===
                    config.PREFERRED_DELIVERY_METHODS_TO_NUMERIC.third_party_courier ||
                delivery_partner_name == DOORDASH
                    ? [this.renderPickupButton, this.renderCompleteButton]
                    : [this.renderPickupButton, this.renderStartDeliveryButton, this.renderCompleteButton],
            "120-3": [this.renderServeButton, this.renderCompleteButton],
            "120-4,5,6": [this.renderCompleteButton],
            "130-1": [this.renderStartDeliveryButton, this.renderCompleteButton],
            "130-2": [this.renderCompleteButton],
            "131-1,2,3,4,5,6":
                getDeliveryServiceProvider(this.props.store) ===
                    config.PREFERRED_DELIVERY_METHODS_TO_NUMERIC.third_party_courier ||
                delivery_partner_name == DOORDASH
                    ? [this.renderCompleteButton]
                    : [this.renderStartDeliveryButton, this.renderCompleteButton],
            "200-1,2,3,4,5,6": [this.renderPickupButton, this.renderStartDeliveryButton],
            other: [this.renderCompleteButton],
        };

        //based on current order, find the correct buttons
        const result = Object.keys(buttonMap).find((key) => {
            const statusTypes = key.split("-");
            const includedStatuses = _.get(statusTypes, "0") ? _.get(statusTypes, "0").split(",") : [];
            const includedTypes = _.get(statusTypes, "1") ? _.get(statusTypes, "1").split(",") : [];
            return (
                includedStatuses.includes(this.getOrderDeliveryStatus()) &&
                includedTypes.includes(String(this.props.shippingMethod))
            );
        });

        let buttons = [];
        if (result) {
            buttons = buttonMap[result];
        } else {
            buttons = buttonMap["other"];
        }

        if (this.props.state.onEdit) {
            buttons[1] = this.renderFinalizeButton;
        }

        if (this.isListStyle() && Array.isArray(buttons)) {
            buttons = buttons.slice(0, 2);
        }

        if (this.isListStyle() && isGroupedOrder(this.order())) {
            buttons.unshift(this.renderGroupByItemSwitch, this.renderMergeButton);
        }
        return buttons;
    };

    getButtonShareProps = (styles) => ({
        style: styles,
        className: cx({
            "content-head-button": true,
            "processing-button": true,
            [this.props.buttonClass]: true,
        }),
    });

    handleOnPrint = () => {
        let { order } = this.props;
        let orderDetailInstance = new LiveOrder(order);

        handlePrintReceipt({
            orderDetail: orderDetailInstance,
            storeData: this.store(),
            orderStatus: "manual",
        });
    };

    showCompleteModal = () => {
        this.props.setState({
            activeId: this.getOrderId(),
            modelController: `${this.getOrderId()}:completeModal`,
        });
    };

    showCancelModal = () => {
        this.props.setState({ activeId: this.getOrderId() });
        this.setState({ showCancel: true });
    };

    showUpdatePaymentModal = () => {
        orderPaymentDialog({
            order: this.order(),
            updateOrderPayment: (payload) => this.props.updateOrder(payload),
            orderStore: this.store(),
        });
    };

    updateOrders = (action, post = {}, apiType = BULK_UPDATE_API_TYPE.live_order) => {
        if (isGroupedOrder(this.order())) {
            this.updateOrderInGroup(action, post, apiType);
        } else {
            this.updateOrderSingular(action, post, apiType);
        }
    };

    updateOrderInGroup = (action, post, apiType) => {
        let orders = Array.isArray(_.get(this.order(), "orders")) ? _.cloneDeep(_.get(this.order(), "orders")) : [];
        this.props.setOrderDetailState({
            selectedOrders: _.reverse(orders),
        });
        this.props.setState({
            bulkUpdateData: {
                showProcessingDialog: true,
                updateStatus: BULK_UPDATE_STATUES.updating,
                postData: {
                    action: action,
                    ...post,
                },
                apiType,
            },
        });
    };

    updateOrderSingular = (action, post, apiType) => {
        const apiAction =
            apiType === BULK_UPDATE_API_TYPE.delivery_status
                ? this.props.updateLiveOrderDeliveryStatus
                : this.props.updateOrder;
        apiAction({
            action,
            order_id: this.getOrderId(),
            order: this.order(),
            orderStore: this.store(),
            ...post,
        });
    };

    isAllowDashDeliveryRequest = () => {
        const doordash_is_enabled = this.props?.store?.storeThirdPartyDelivery?.doordash?.is_enabled;
        const preferred_delivery_method = this.props?.store?.storePickupDelivery?.preferred_delivery_method;
        const enable_3rdparty_local_delivery = this.props?.store?.storePickupDelivery?.enable_3rdparty_local_delivery;
        const delivery_partner_name = this.order()?.delivery_partner_name;
        const delivery_status = this.order()?.delivery_status;
        const courier_status = this.order()?.courier_status;

        return (
            helper.isDelivery(this.props.shippingMethod) &&
            doordash_is_enabled === ENABLED &&
            (preferred_delivery_method == ENABLE_DELIVERY || enable_3rdparty_local_delivery == THIRD_PARTY_ENABLE) &&
            ((delivery_status >= ACCEPTED_DELIVERY_STATUS && delivery_partner_name != DOORDASH) ||
                (delivery_partner_name == DOORDASH &&
                    (courier_status ==
                        config.ORDER_DELIVERY_STATUS_MAPPING_TO_NUMERIC
                            .delivery_task_cancelled_by_3rd_party_delivery_partner ||
                        courier_status ==
                            config.ORDER_DELIVERY_STATUS_MAPPING_TO_NUMERIC.delivery_task_cancelled_by_merchant)))
        );
    };

    showComplete = () => {
        return !this.isThirdPartyCourierCollectedPackage();
    };

    showCancelDelivery = () => {
        const delivery_partner_name = this.order()?.delivery_partner_name;
        const tracking_url = this.order()?.tracking_url;
        const delivery_status = this.order()?.courier_status;
        return (
            delivery_partner_name === DOORDASH &&
            !_.isEmpty(tracking_url) &&
            !_.isEmpty(this.getDeliveryTaskId()) &&
            delivery_status == DELIVERING_STATUS
        );
    };

    showCancelDeliveryModal = () => {
        this.props.setState({
            activeId: this.getOrderId(),
            modelController: `${this.getOrderId()}:cancelDeliveryModal`,
        });
    };

    showRequestDeliveryServiceModal = () => {
        this.props.setState({
            activeId: this.getOrderId(),
            modelController: `${this.getOrderId()}:requestDeliveryModal`,
        });
    };

    renderCancelDeliveryModal = () => {
        const modelController = _.get(this.props, "state.modelController");
        const currentControllerKey = `${this.getOrderId()}:cancelDeliveryModal`;
        const showModal = modelController === currentControllerKey;
        return (
            <CancelDeliveryModal
                showModal={showModal}
                order={this.order()}
                deliveryID={this.getDeliveryTaskId()}
                cancelDoordashDelivery={this.props.cancelDoordashDelivery}
                onSubmitUpdate={() => this.updateOrders(ORDER_DETAIL_ACTIONS.to_cancel)}
            />
        );
    };

    renderRequestDeliverModal = () => {
        const modelController = _.get(this.props, "state.modelController");
        const currentControllerKey = `${this.getOrderId()}:requestDeliveryModal`;
        const showModal = modelController === currentControllerKey;
        const estimatedDeliveryFee = this.props?.store?.doordashDeliveryDetails?.estDeliveryFee;
        return (
            <DoordashModal
                showModal={showModal}
                estimatedDeliveryFee={estimatedDeliveryFee}
                onCloseClick={() => {
                    this.setState({ showCancel: false });
                    this.props.setState({ modelController: "" });
                }}
                order={this.order()}
                onSubmitUpdate={() => this.updateOrders(ORDER_DETAIL_ACTIONS.to_processing)}
            />
        );
    };

    showDeliveryWebsite = () => {
        return !_.isEmpty(this.getDeliveryTaskId());
    };

    renderMoreActionsMenu = () => {
        const isPaid = this.getPayStatus() === config.PAY_STATUS_TO_NUMERIC.paid;

        const data = [
            {
                label: "print",
                onClick: this.handleOnPrint,
            },
            {
                label: "cancel_order",
                onClick: this.showCancelModal,
            },
        ];

        if (this.showComplete()) {
            data.push({
                label: "complete",
                onClick: this.showCompleteModal,
            });
        }

        if (allowEditPaymemnt(this.order()) && !isGroupedOrder(this.order())) {
            data.push({
                label: "update_payment_method",
                onClick: this.showUpdatePaymentModal,
            });
        }

        if (this.isAllowDashDeliveryRequest()) {
            data.push({
                label: "request_delivery_service",
                onClick: this.showRequestDeliveryServiceModal,
            });
        }

        if (this.showCancelDelivery()) {
            data.push({
                label: "cancel_delivery",
                onClick: this.showCancelDeliveryModal,
            });
        }
        if (this.showDeliveryWebsite()) {
            data.push({
                label: "track_delivery",
                onClick: () => {
                    this.props.getDeliveryURL({
                        delivery_id: this.getDeliveryTaskId(),
                        openNewWindows: () =>
                            this.props.setState({
                                activeId: this.getOrderId(),
                                modelController: `${this.getOrderId()}:doordashTrackingModal`,
                            }),
                    });
                },
            });
        }

        if (showMarkAsPaidOrUnpaid(this.order())) {
            data.push({
                label: isPaid ? "mark_as_unpaid" : "mark_as_paid",
                onClick: () => {
                    isPaid ? this.props.markAsUnPaid(this.getOrderId()) : this.props.markAsPaid(this.getOrderId());
                },
            });
        }

        return (
            <Menu>
                {data.map((menu) => (
                    <Menu.Item key={menu.label} className="more-drop-down-text" onClick={() => menu.onClick()}>
                        <FormattedMessage id={menu.label} />
                    </Menu.Item>
                ))}
            </Menu>
        );
    };

    renderMoreActionsButton = (styles) => {
        return (
            <Dropdown placement="top" overlay={this.renderMoreActionsMenu()}>
                <AntdButton {...this.getButtonShareProps(styles)} type="default">
                    <FormattedMessage id="more_actions" />
                    <IoMdArrowDropup />
                </AntdButton>
            </Dropdown>
        );
    };

    renderRejectButton = (styles) => {
        return (
            <StyledButton1 key="reject-button" {...this.getButtonShareProps(styles)} onClick={this.showCancelModal}>
                <b>
                    <FormattedMessage id="reject" />
                </b>
            </StyledButton1>
        );
    };

    renderAcceptButton = (styles) => (
        <StyledButton2
            key="accept-button"
            {...this.getButtonShareProps(styles)}
            onClick={() => {
                //this.props.fetchDeliveryFeeEstimate({ order_id: this.order()?.ord_id });
                this.props.setState({ activeId: this.getOrderId() });
                if (helper.isEatIn(this.props.shippingMethod) && !requireDineInDueTime(this.store())) {
                    this.props.requireDineInConfirmation
                        ? this.renderConfirmAcceptModal()
                        : this.updateOrders(
                              ORDER_DELIVERY_STATUS_ACTIONS.to_accept,
                              {},
                              BULK_UPDATE_API_TYPE.delivery_status
                          );
                } else {
                    this.setState({ showProccess: true });
                }
            }}
        >
            <b>
                <FormattedMessage id="accept" />
            </b>
        </StyledButton2>
    );

    renderServeButton = (styles) => (
        <StyledButton3
            {...this.getButtonShareProps(styles)}
            onClick={() => {
                this.props.setState({ activeId: this.getOrderId() });
                if (this.props.requireDineInConfirmation) {
                    this.renderConfirmServeModal();
                } else {
                    this.updateOrders(
                        ORDER_DELIVERY_STATUS_ACTIONS.to_served,
                        {},
                        BULK_UPDATE_API_TYPE.delivery_status
                    );
                }
            }}
        >
            <b>
                <FormattedMessage id={isRestaurant(this.store()) ? "serve_restaurant" : "serve_others"} />
            </b>
        </StyledButton3>
    );

    renderMergeButton = (styles) => (
        <StyledButton3
            {...this.getButtonShareProps(styles)}
            onClick={() => {
                this.props.setState({ activeId: this.getOrderId() });
                this.renderConfirmMergeModal();
            }}
        >
            <b>
                <FormattedMessage id="merge" />
            </b>
        </StyledButton3>
    );

    renderStartPreparingButton = (styles) => {
        return (
            <StyledButton2
                {...this.getButtonShareProps(styles)}
                onClick={() => {
                    this.props.setState({ activeId: this.getOrderId() });
                    this.renderConfirmStartPreparingModel();
                }}
            >
                <b>
                    <FormattedMessage id="start_preparing" />
                </b>
            </StyledButton2>
        );
    };

    renderPickupButton = (styles) => {
        const text = helper.isDelivery(this.props.shippingMethod) ? "ready_for_delivery" : "ready_for_pick_up";
        return (
            <StyledButton2
                {...this.getButtonShareProps(styles)}
                onClick={() => {
                    this.props.setState({ activeId: this.getOrderId() });
                    this.renderConfirmPickupModal();
                }}
            >
                <b>
                    <FormattedMessage id={text} />
                </b>
            </StyledButton2>
        );
    };

    renderFinalizeButton = (styles) => {
        const onClick = () => {
            const orderId = this.getOrderId();
            let expansionControllers = _.cloneDeep(_.get(this.props, "state.expansionControllers", {}));
            let controller = _.cloneDeep(_.get(this.props, `state.expansionControllers.${orderId}`, {}));
            controller.showDetail = true;
            expansionControllers[orderId] = controller;
            this.props.setState({
                modelController: `${orderId}:finalizeModal`,
                expansionControllers: expansionControllers,
                activeId: orderId,
            });
        };
        return (
            <StyledButton2 {...this.getButtonShareProps(styles)} onClick={() => onClick()}>
                <b>
                    <FormattedMessage id="apply_changes" />
                </b>
            </StyledButton2>
        );
    };

    renderStartDeliveryButton = (styles) => {
        return (
            <StyledButton2
                {...this.getButtonShareProps(styles)}
                onClick={() => {
                    this.props.setState({ activeId: this.getOrderId() });
                    this.renderConfirmStartDeliveryModel();
                }}
            >
                <b>
                    <FormattedMessage id="start_delivery" />
                </b>
            </StyledButton2>
        );
    };

    renderCompleteButton = (styles) => {
        const textId = "complete";
        return (
            <StyledButton3
                {...this.getButtonShareProps(styles)}
                onClick={this.showCompleteModal}
                disabled={this.isThirdPartyCourierCollectedPackage()}
            >
                <b>
                    <FormattedMessage id={textId} />
                </b>
            </StyledButton3>
        );
    };

    renderGroupByItemSwitch = (styles) => {
        const on = _.get(this.props, "state.groupedByItem", false);
        const onChange = () => {
            this.props.setState({ groupedByItem: !on });
        };
        return (
            <div {...this.getButtonShareProps(styles)}>
                <div className="content-head-switch-group">
                    <FormattedMessage id="group_by_items" />
                </div>
                <div>
                    <Switch checked={on} onChange={() => onChange()} />
                </div>
            </div>
        );
    };

    renderConfirmAcceptModal = () => {
        const updateStatus = () =>
            this.updateOrders(ORDER_DELIVERY_STATUS_ACTIONS.to_accept, {}, BULK_UPDATE_API_TYPE.delivery_status);
        Modal.confirm({
            title: this.str({ id: "warning" }),
            content: this.str({ id: "start_processing_message" }),
            onOk() {
                updateStatus();
            },
            okText: <b>{this.str({ id: "confirm" })}</b>,
            cancelText: <b>{this.str({ id: "cancel" })}</b>,
        });
    };

    renderConfirmServeModal = () => {
        const updateStatus = () =>
            this.updateOrders(ORDER_DELIVERY_STATUS_ACTIONS.to_served, {}, BULK_UPDATE_API_TYPE.delivery_status);
        const contentId = isRestaurant(this.store())
            ? "start_serving_message_restaurant"
            : "start_serving_message_other";
        Modal.confirm({
            title: this.str({ id: "warning" }),
            content: this.str({ id: contentId }),
            onOk() {
                updateStatus();
            },
            okText: <b>{this.str({ id: "confirm" })}</b>,
            cancelText: <b>{this.str({ id: "cancel" })}</b>,
        });
    };

    renderConfirmPickupModal = () => {
        const text = helper.isDelivery(this.props.shippingMethod)
            ? "confirm_ready_for_delivery"
            : "confirm_ready_for_pick_up";
        const updateStatus = () =>
            this.updateOrders(
                ORDER_DELIVERY_STATUS_ACTIONS.to_ready_for_pickup,
                {},
                BULK_UPDATE_API_TYPE.delivery_status
            );
        Modal.confirm({
            title: this.str({ id: "warning" }),
            content: this.str({ id: text }),
            onOk() {
                updateStatus();
            },
            okText: <b>{this.str({ id: "confirm" })}</b>,
            cancelText: <b>{this.str({ id: "cancel" })}</b>,
        });
    };

    renderConfirmCompleteModel = () => {
        const updateOrder = () => this.updateOrders(ORDER_DETAIL_ACTIONS.to_complete);
        const closeOrder = () => this.props.setState({ modelController: "" });
        return (
            <WarningModal
                onOk={() => {
                    updateOrder();
                    closeOrder();
                }}
                onCancel={() => closeOrder()}
                visible={this.props.modelController === `${this.getOrderId()}:completeModal`}
                contentText={"mark_as_complete_message"}
            />
        );
    };

    renderConfirmStartPreparingModel = () => {
        const updateStatus = () =>
            this.updateOrders(ORDER_DELIVERY_STATUS_ACTIONS.to_preparing, {}, BULK_UPDATE_API_TYPE.delivery_status);
        Modal.confirm({
            title: this.str({ id: "warning" }),
            content: this.str({ id: "confirm_start_preparing" }),
            onOk() {
                updateStatus();
            },
            okText: <b>{this.str({ id: "continue" })}</b>,
            cancelText: <b>{this.str({ id: "cancel" })}</b>,
        });
    };

    renderConfirmStartDeliveryModel = () => {
        const updateStatus = () =>
            this.updateOrders(ORDER_DELIVERY_STATUS_ACTIONS.to_delivering, {}, BULK_UPDATE_API_TYPE.delivery_status);
        Modal.confirm({
            title: this.str({ id: "warning" }),
            content: this.str({ id: "confirm_start_delivery" }),
            onOk() {
                updateStatus();
            },
            okText: <b>{this.str({ id: "confirm" })}</b>,
            cancelText: <b>{this.str({ id: "cancel" })}</b>,
        });
    };

    renderConfirmMergeModal = () => {
        const mergeOrders = () => {
            this.props.mergeOrders({
                order_ids: _.get(this.order(), "orderIds", []),
            });
        };
        Modal.confirm({
            title: this.str({ id: "warning" }),
            content: this.str({ id: "confirm_merge" }),
            onOk() {
                mergeOrders();
            },
            okText: <b>{this.str({ id: "confirm" })}</b>,
            cancelText: <b>{this.str({ id: "cancel" })}</b>,
        });
    };

    renderCancelModal = () => {
        const modelController = _.get(this.props, "state.modelController");
        const currentContollerKey = `${this.getOrderId()}:cancelModal`;
        const showModal = this.state.showCancel || modelController === currentContollerKey;
        return (
            <CancelModal
                onCloseClick={() => {
                    this.setState({ showCancel: false });
                    this.props.setState({ modelController: "" });
                }}
                onSubmitClick={(comment) => {
                    this.updateOrders(ORDER_DETAIL_ACTIONS.to_cancel, {
                        comment,
                    });
                }}
                displayModal={showModal}
                order={this.order()}
            />
        );
    };

    renderProcessingModal = () => {
        const modelController = _.get(this.props, "state.modelController");
        const currentContollerKey = `${this.getOrderId()}:processingModal`;
        const showModal = this.state.showProccess || modelController === currentContollerKey;
        const estimatedDeliveryFee = this.props?.store?.doordashDeliveryDetails?.estDeliveryFee;
        return (
            <ProcessingModal
                onCloseClick={() => {
                    this.setState({ showProccess: false });
                    this.props.setState({ modelController: "" });
                }}
                estimatedDeliveryFee={estimatedDeliveryFee}
                displayModal={showModal}
                orderStore={this.store()}
                order={this.order()}
                onSubmitClick={(values) => {
                    this.updateOrders(
                        ORDER_DELIVERY_STATUS_ACTIONS.to_accept,
                        values,
                        BULK_UPDATE_API_TYPE.delivery_status
                    );
                }}
                onSubmitUpdate={() => this.updateOrders(ORDER_DELIVERY_STATUS_ACTIONS.to_preparing)}
            />
        );
    };

    shouldFlexColumn = () => {
        const buttons = this.getButtons();
        const num = buttons.length;
        if (_.isEqual(buttons, [this.renderRejectButton, this.renderAcceptButton])) {
            return false;
        }
        if (_.isEqual(buttons, [this.renderRejectButton, this.renderFinalizeButton])) {
            return false;
        }
        return this.props.lan === "en" ? num >= 2 : num >= 3;
    };

    buttonStyle = () => {
        let result = {};
        if (this.shouldFlexColumn()) {
            result.width = "95%";
            result.marginRight = 0;
        }
        if (this.isListStyle() && this.getButtons().length === 1) {
            result.width = "100%";
        }
        if (this.isListStyle() && this.getButtons().length === 2) {
            result.maxWidth = "20vw";
        }
        if (this.isListStyle() && this.getButtons().length === 3) {
            result.maxWidth = "18vw";
        }
        if (this.isListStyle() && this.getButtons().length === 4 && !isGroupedOrder(this.order())) {
            result.maxWidth = "13vw";
        }
        if (this.isListStyle() && this.getButtons().length === 4 && isGroupedOrder(this.order())) {
            result.width = "10vw";
        }
        return result;
    };

    getButtonsStyle = () => {
        let result = {
            flexDirection: this.shouldFlexColumn() ? "column" : "row",
        };
        if (this.isListStyle()) {
            result.display = "flex";
            result.justifyContent = "space-between";
            result.width = "90%";
            result.flexDirection = "row";
        }
        if (this.isListStyle() && this.getButtons().length === 1) {
            result.justifyContent = "center";
        }
        if (this.isListStyle() && this.getButtons().length === 3) {
            result.width = "100%";
        }
        if (this.isListStyle() && this.getButtons().length === 4) {
            result.width = "100%";
        }
        return result;
    };

    getButtonColSpan = (numOfButtons) => {
        if (this.isListStyle()) {
            numOfButtons++;
        }
        const COLUMN_SPAN = 24;
        return COLUMN_SPAN / numOfButtons;
    };

    renderListStyleButtons = () => {
        const buttons = this.getButtons();
        return (
            <Row gutter={8} className="content-head-col-processing-buttons">
                {this.getButtons().map((button, i) => (
                    <Col
                        key={`processing-buttons-${i}`}
                        span={this.getButtonColSpan(buttons.length)}
                        className="processing-buttons-col"
                    >
                        {button(this.buttonStyle())}
                    </Col>
                ))}
                {this.isListStyle() ? (
                    <Col span={this.getButtonColSpan(buttons.length)} className="processing-buttons-col">
                        {this.renderMoreActionsButton(this.buttonStyle())}
                    </Col>
                ) : null}
            </Row>
        );
    };

    renderGridStyleButtons = () => {
        return (
            <div className="content-head-col-processing-buttons" style={this.getButtonsStyle()}>
                {this.getButtons().map((button) => button(this.buttonStyle()))}
            </div>
        );
    };
    renderDoordashTrackingModal = () => {
        const modelController = _.get(this.props, "state.modelController");
        const currentControllerKey = `${this.getOrderId()}:doordashTrackingModal`;
        const showModal = modelController === currentControllerKey;

        return (
            <DoordashTrackingModal
                showModal={showModal}
                link={this.order()?.tracking_url}
                onCloseClick={() => {
                    this.props.setState({ modelController: "" });
                }}
            />
        );
    };
    render() {
        return (
            <>
                {this.isListStyle() ? this.renderListStyleButtons() : this.renderGridStyleButtons()}
                {this.renderCancelModal()}
                {this.renderRequestDeliverModal()}
                {this.renderCancelDeliveryModal()}
                {this.renderProcessingModal()}
                {this.renderConfirmCompleteModel()}
                {this.renderDoordashTrackingModal()}
            </>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    lan: _.get(state, "setting.lan", "en"),
    store: _.get(state, "store", {}),
    storesData: _.get(state, "managedStores.activeStores", []),
    state: _.get(state, "liveOrders", {}),
    requireDineInConfirmation: _.get(state, "setting.requireDineInConfirmation", false),
    modelController: _.get(state, "liveOrders.modelController", ""),
    orderCarriers: _.get(state, "shipmentTracking.orderCarriers", []),
    shippingMethod: _.get(ownProps, "order.shp_mtd", null),
});

const mapDispatchToProps = {
    updateLiveOrderDeliveryStatus: _actions.updateLiveOrderDeliveryStatus,
    updateOrder: (data) => _actions.updateOrder({ data }),
    mergeOrders: (data) => _actions.mergeOrders({ data }),
    markAsPaid: _actions.markAsPaid,
    markAsUnPaid: _actions.markAsUnPaid,
    setState: _actions.setState,
    setOrderDetailState: _actionsOrder.setState,
    fetchDeliveryFeeEstimate: fetchDeliveryFeeEstimate,
    createDoordashDelivery: createDoordashDelivery,
    cancelDoordashDelivery: cancelDoordashDelivery,
    getDeliveryURL,
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(HeadRowButons));
