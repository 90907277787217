import { RootState } from "app/reducer";
import config from "config";
import _ from "lodash";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { setPaymentState } from "slices/store";
import { isBool } from "util/helper";
import "../payment.scss";
import { renderHalfSwitch } from "../_components/payment-functions";
import {
    renderHalfMultiNumberInput,
    renderHalfMultiTextInput,
    renderHalfTxtInput,
    renderHalfTxtSelec,
    renderTop,
} from "./payment-functions";
import { Button, Input } from "antd";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SelectOption } from "../../../../../components/form";
import { generateCloverToken, refreshCloverToken } from "services/store";

const CardPresentKeys = {
    vendor: "card_present_vendor",
    integration_type: "card_present_integration_type",
    merchant_id: "card_present_merch_id",
    access_token: "card_present_access_token",
    access_token_expiration: "card_present_access_token_expiration",
    refresh_token: "card_present_refresh_token",
    refresh_token_expiration: "card_present_refresh_token_expiration",
    pos_id: "card_present_pos_id",
    enabled: "card_present_enabled",
    device_ids: "card_present_device_ids",
    customer_ids: "card_present_customer_ids",
    is_debug: "card_present_is_debug",
    app_id: "card_present_app_id",
    store_id: "card_present_pos_store_id",
    enable_tips: "card_present_enable_tips",
    apiSecret: "card_present_api_secret",
    authKey: "card_present_auth_key",
    configCode: "card_present_config_code",
};

const VENDOR_OPTIONS = [
    {
        label: <FormattedMessage id="clover" />,
        value: config.CARD_PRESENT_VENDORS.clover,
    },
    {
        label: <FormattedMessage id="global_payment" />,
        value: config.CARD_PRESENT_VENDORS.global_payment,
    },
    {
        label: <FormattedMessage id="moneris" />,
        value: config.CARD_PRESENT_VENDORS.moneris,
    },
];

const CLOVER_INTEGRATION_TYPE_OPTIONS = [
    {
        label: <FormattedMessage id="rest_pay_api" />,
        value: config.CLOVER_INTEGRATION_TYPES.rest_pay_api,
    },
    {
        label: <FormattedMessage id="remote_pay_sdk" />,
        value: config.CLOVER_INTEGRATION_TYPES.remote_pay_sdk,
    },
];

const MONERIS_INTEGRATION_TYPE_OPTIONS = [
    {
        label: <FormattedMessage id="cloud_solution" />,
        value: config.MONERIS_INTEGRATION_TYPES.cloud_solution,
    },
    {
        label: <FormattedMessage id="semi_integrated_solution" />,
        value: config.MONERIS_INTEGRATION_TYPES.semi_integrated_solution,
    },
];

const modeOptions: SelectOption[] = [
    { value: 1, label: "Sandbox" },
    { value: 0, label: "Production" },
];

interface CardPresentStateProps {
    paymentOptions: any;
    roles: any;
}

interface CardPresentProps extends CardPresentStateProps, PropsFromRedux {
    lan: string;
}

const CardPresent = (props: CardPresentProps) => {
    const { setPaymentState, paymentOptions } = props;
    const {
        card_present_vendor,
        card_present_merch_id,
        card_present_access_token,
        // card_present_access_token_expiration,
        card_present_refresh_token,
        card_present_refresh_token_expiration,
        card_present_pos_id,
        card_present_enabled,
        card_present_device_ids,
        card_present_customer_ids,
        card_present_app_id,
        card_present_pos_store_id,
        card_present_enable_tips,
        card_present_api_secret,
        card_present_auth_key,
        card_present_config_code,
    } = paymentOptions;
    const card_present_integration_type = Number(paymentOptions.card_present_integration_type);
    const card_present_is_debug = Boolean(paymentOptions.card_present_is_debug);

    const [deviceId, setDeviceId] = useState("");
    const [customerId, setCustomerId] = useState<number | undefined>(undefined);
    const [isLoaded, setIsLoaded] = useState(false);
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoaded(true);
        if (!card_present_vendor) {
            setPaymentState({
                name: CardPresentKeys.vendor,
                value: VENDOR_OPTIONS[0].value,
            });
        }

        //Check enable tips starting value. If undefined/null, set to enabled by default
        if (card_present_enable_tips == null) {
            setPaymentState({
                name: CardPresentKeys.enable_tips,
                value: 1,
            });
        }
    }, []);

    useEffect(() => {
        if (isClover() && isLoaded) {
            setPaymentState({
                name: CardPresentKeys.integration_type,
                value: config.CLOVER_INTEGRATION_TYPES.rest_pay_api,
            });
        } else if (isMoneris() && isLoaded) {
            setPaymentState({
                name: CardPresentKeys.integration_type,
                value: config.MONERIS_INTEGRATION_TYPES.cloud_solution,
            });
        } else if (!isClover() && !isMoneris()) {
            setPaymentState({
                name: CardPresentKeys.integration_type,
                value: null,
            });
        }
    }, [card_present_vendor]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const merchantId = searchParams.get("merchant_id");
        const authorizationCode = searchParams.get("code");
        const isLive = searchParams.get("is_live") == "true";

        if (merchantId != null) {
            setPaymentState({
                name: CardPresentKeys.merchant_id,
                value: merchantId,
            });

            setPaymentState({
                name: CardPresentKeys.is_debug,
                value: !isLive,
            });

            // dispatch(
            //     generateCloverToken({
            //         is_live: !card_present_is_debug,
            //         authorization_code: authorizationCode,
            //     })
            // );
            //     dispatch(setIsOnboardLoaded(1));
            //     setMerchantIdInPayPal(merchantId);
            //     dispatch(setPaymentState({ name: "ppcp_enabled", value: true }));
            //     dispatch(setPaymentState({ name: "ppcp_merchant_id", value: merchantId }));
            //     dispatch(setPaymentState({ name: "ppcp_txn_type", value: paymentOptions?.ppcp_txn_type }));
            //     dispatch(setPaymentState({ name: "ppcp_payment_paypal", value: paymentOptions?.ppcp_payment_paypal }));
            //     dispatch(
            //         setPaymentState({ name: "ppcp_payment_apple_pay", value: paymentOptions?.ppcp_payment_apple_pay })
            //     );
            //     dispatch(
            //         setPaymentState({ name: "ppcp_payment_google_pay", value: paymentOptions?.ppcp_payment_google_pay })
            //     );
            //     dispatch(setPaymentState({ name: "ppcp_mode", value: paymentOptions?.ppcp_mode }));
            //     dispatch(setPaymentState({ name: "ppcp_partner_fee", value: [] }));
            //     dispatch(
            //         setPaymentState({
            //             name: "ppcp_contingency_3d_secure",
            //             value: paymentOptions?.ppcp_contingency_3d_secure,
            //         })
            //     );
            //     dispatch(
            //         setPaymentState({
            //             name: "ppcp_thirdparty_token",
            //             value: paymentOptions?.ppcp_thirdparty_token,
            //         })
            //     );
            //     dispatch(
            //         setPaymentState({
            //             name: "ppcp_thirdparty_token_created_at",
            //             value: paymentOptions?.ppcp_thirdparty_token_created_at,
            //         })
            //     );
        }
    }, [location.search]);

    const setState = (name: string, value: any) => setPaymentState({ name, value });

    const isClover = () => {
        return card_present_vendor === config.CARD_PRESENT_VENDORS.clover;
    };

    const isCloverRESTPayAPI = () => {
        return isClover() && card_present_integration_type === config.CLOVER_INTEGRATION_TYPES.rest_pay_api;
    };

    const isCloverRemotePaySDK = () => {
        return isClover() && card_present_integration_type === config.CLOVER_INTEGRATION_TYPES.remote_pay_sdk;
    };

    const isMoneris = () => {
        return card_present_vendor === config.CARD_PRESENT_VENDORS.moneris;
    };

    const isMonerisSemiIntegrated = () => {
        return (
            isMoneris() && card_present_integration_type === config.MONERIS_INTEGRATION_TYPES.semi_integrated_solution
        );
    };

    const isMonerisCloud = () => {
        return isMoneris() && card_present_integration_type === config.MONERIS_INTEGRATION_TYPES.cloud_solution;
    };

    const isGlobalPayment = () => {
        return card_present_vendor === config.CARD_PRESENT_VENDORS.global_payment;
    };
    const isSuperAdmin = () => {
        return props.roles.includes(999);
    };

    const shouldShowCloverConnectButton = () => {
        return isClover() && isCloverRESTPayAPI() && !card_present_merch_id;
    };

    const shouldShowCloverDisconnectButton = () => {
        return isClover() && isCloverRESTPayAPI() && card_present_merch_id;
    };

    const shouldRender = (key: string): boolean => {
        switch (key) {
            case CardPresentKeys.store_id:
                return isMonerisCloud();
            case CardPresentKeys.integration_type:
                return isClover() || isMoneris();
            case CardPresentKeys.merchant_id:
                return isMoneris() || shouldShowCloverDisconnectButton();
            case CardPresentKeys.app_id:
                return (!isClover() && !isMoneris()) || isCloverRemotePaySDK() || isGlobalPayment();
            case CardPresentKeys.pos_id:
                return !isClover() && !isMoneris() && !isGlobalPayment();
            case CardPresentKeys.device_ids:
                return !isClover() && !isMoneris() && !isGlobalPayment();
            case CardPresentKeys.customer_ids:
                return (!isClover() && !isMoneris() && !isGlobalPayment()) || isCloverRemotePaySDK();
            case CardPresentKeys.apiSecret:
                return isGlobalPayment() && isSuperAdmin();
            case CardPresentKeys.authKey:
                return isGlobalPayment() && isSuperAdmin();
            case CardPresentKeys.refresh_token:
                return shouldShowCloverDisconnectButton();
            case CardPresentKeys.access_token:
                return !isMonerisSemiIntegrated() && !shouldShowCloverConnectButton();
            case CardPresentKeys.configCode:
                return isMonerisSemiIntegrated();
            case CardPresentKeys.is_debug:
                return !isCloverRESTPayAPI();
            case CardPresentKeys.enable_tips:
                return !shouldShowCloverConnectButton();
            default:
                return true;
        }
    };

    const changeUTCTimeStampToLocalDateTime = (UTCTimeStamp: number): string => {
        const date = new Date(UTCTimeStamp * 1000);
        return date.toLocaleString();
    };

    const handleOnDeviceIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDeviceId(event.target.value);
    };

    const handleRemoveDeviceId = (index: number) => {
        const deviceIds = _.cloneDeep(card_present_device_ids) ?? [];
        deviceIds.splice(index, 1);
        setState(CardPresentKeys.device_ids, deviceIds);
    };

    const handleAddDeviceId = (event: React.MouseEvent<HTMLElement, MouseEvent>, value?: string) => {
        if (value) {
            const deviceIds = _.cloneDeep(card_present_device_ids) ?? [];
            deviceIds.push(value);
            setState(CardPresentKeys.device_ids, deviceIds);
        }

        setDeviceId("");
    };

    const handleOnCustomerIdChange = (value?: number) => {
        setCustomerId(value);
    };

    const handleRemoveCustomerId = (index: number) => {
        const customerId = _.cloneDeep(card_present_customer_ids) ?? [];
        customerId.splice(index, 1);
        setState(CardPresentKeys.customer_ids, customerId);
    };

    const handleAddCustomerId = (event: React.MouseEvent<HTMLElement, MouseEvent>, value?: number) => {
        if (value) {
            const customerId = _.cloneDeep(card_present_customer_ids) ?? [];
            customerId.push(value);
            setState(CardPresentKeys.customer_ids, customerId);
        }

        setCustomerId(undefined);
    };

    const switchChange = (name: string, value: any) => setPaymentState({ name, value: isBool(!value) });

    const renderCloverConnectButton = () => (
        <div>
            <div className="payment-title-container">
                {renderHalfTxtSelec(
                    "mode",
                    `${CardPresentKeys.is_debug}`,
                    Number(card_present_is_debug),
                    modeOptions,
                    setState,
                    true
                )}

                <div className="payment-subtitle mr-3 mb-0">
                    <Button
                        type="primary"
                        size="large"
                        onClick={() => {
                            OnCloverConnectButtonHandler();
                        }}
                        className="save-button-handle-onboard"
                    >
                        <FormattedMessage id="connect" />
                    </Button>
                </div>
            </div>
            {/* <div className="payment-desc">
                <FormattedMessage id={"ppcp_account_setting_tip"} />
            </div> */}
        </div>
    );

    const renderCloverDisconnectButton = () => (
        <div>
            <div className="payment-title-container">
                {renderHalfTxtInput(
                    "mode",
                    `${CardPresentKeys.is_debug}`,
                    card_present_is_debug ? "Sandbox" : "Production",
                    setState,
                    false,
                    false,
                    undefined,
                    undefined,
                    shouldShowCloverDisconnectButton()
                )}
                <div className="payment-subtitle mr-3 mb-0">
                    <Button
                        type="primary"
                        size="large"
                        onClick={() => {
                            OnCloverDisconnectButtonHandler();
                        }}
                        className="save-button-handle-onboard"
                    >
                        <FormattedMessage id="disconnect" />
                    </Button>
                </div>
            </div>
            {/* <div className="payment-desc">
                <FormattedMessage id={"ppcp_account_setting_tip"} />
            </div> */}
        </div>
    );

    const renderCloverRefreshToken = () => {
        return (
            <div className="halfish-size d-flex flex-column justify-content-start align-items-start">
                <div className="payment-subtitle">
                    <FormattedMessage id={"refresh_token"} />
                </div>

                <Input.Group className="multi-input-group" compact>
                    <Input
                        className="multi-text-input"
                        name={"clover_ref"}
                        value={card_present_refresh_token}
                        disabled={!isSuperAdmin()}
                        readOnly
                    />
                    <Button
                        className="multi-text-button"
                        type="primary"
                        disabled={!isSuperAdmin()}
                        onClick={() => {
                            const searchParams = new URLSearchParams(location.search);
                            const authorizationCode = searchParams.get("code");
                            dispatch(
                                generateCloverToken({
                                    is_live: !card_present_is_debug,
                                    authorization_code: authorizationCode,
                                })
                            );
                            // dispatch(refreshCloverToken({ is_live: !card_present_is_debug, merchant_id: card_present_merch_id, store_id: 1666}))
                        }}
                        size="small"
                    >
                        <FormattedMessage id={"generate"} />
                    </Button>
                </Input.Group>
                {card_present_refresh_token_expiration && (
                    <div className="ppcp-thirdparty-token-created-container">
                        <FormattedMessage id={"expired_at"} />
                        {` ${changeUTCTimeStampToLocalDateTime(card_present_refresh_token_expiration)}`}
                    </div>
                )}
            </div>
        );
    };

    const OnCloverConnectButtonHandler = async () => {
        const CLOVER_BASE_URL = card_present_is_debug
            ? config.CLOVER.API_BASE_URL.SANDBOX
            : config.CLOVER.API_BASE_URL.LIVE;
        const CLOVER_CLIENT_ID = card_present_is_debug ? config.CLOVER.CLIENT_ID.SANDBOX : config.CLOVER.CLIENT_ID.LIVE;
        const isLive = String(!card_present_is_debug);

        // const RIDIRECT_URL = `http://localhost:3000/settings/paymentOptions?tab=card_present_setting&is_live=${isLive}`;
        const RIDIRECT_URL = `${config.ROOT_URL}settings/paymentOptions?tab=card_present_setting&is_live=${isLive}`;

        // Construct the URL dynamically by replacing placeholders
        const url = `${CLOVER_BASE_URL}/oauth/v2/authorize?client_id=${CLOVER_CLIENT_ID}&redirect_uri=${encodeURIComponent(
            RIDIRECT_URL
        )}`;
        window.open(url, "_blank");
    };

    const OnCloverDisconnectButtonHandler = async () => {
        dispatch(setPaymentState({ name: CardPresentKeys.merchant_id, value: "" }));
        dispatch(setPaymentState({ name: CardPresentKeys.access_token, value: "" }));
        dispatch(setPaymentState({ name: CardPresentKeys.access_token_expiration, value: "" }));
        dispatch(setPaymentState({ name: CardPresentKeys.refresh_token, value: "" }));
        dispatch(setPaymentState({ name: CardPresentKeys.refresh_token_expiration, value: "" }));
    };

    return (
        <div className="white-single-block d-flex flex-column justify-content-start align-items-start">
            {renderTop(
                "card_present_setting",
                "card_present_setting_tip",
                `${CardPresentKeys.enabled}`,
                card_present_enabled,
                switchChange
            )}
            {card_present_enabled ? (
                <div className="payment-input-container">
                    {renderHalfTxtSelec(
                        "vendor",
                        `${CardPresentKeys.vendor}`,
                        card_present_vendor,
                        VENDOR_OPTIONS,
                        setState,
                        true
                    )}
                    {shouldRender(CardPresentKeys.integration_type) &&
                        renderHalfTxtSelec(
                            "integration_type",
                            `${CardPresentKeys.integration_type}`,
                            Number(card_present_integration_type),
                            isClover() ? CLOVER_INTEGRATION_TYPE_OPTIONS : MONERIS_INTEGRATION_TYPE_OPTIONS,
                            setState,
                            true
                        )}
                    <div className="card-present-switch-row">
                        {shouldShowCloverConnectButton() && renderCloverConnectButton()}
                        {shouldShowCloverDisconnectButton() && renderCloverDisconnectButton()}
                    </div>
                    {shouldRender(CardPresentKeys.app_id) &&
                        renderHalfTxtInput(
                            "app_id",
                            `${CardPresentKeys.app_id}`,
                            card_present_app_id,
                            setState,
                            false,
                            true
                        )}
                    {shouldRender(CardPresentKeys.store_id) &&
                        renderHalfTxtInput(
                            "store_id",
                            `${CardPresentKeys.store_id}`,
                            card_present_pos_store_id,
                            setState,
                            false,
                            true
                        )}
                    {shouldRender(CardPresentKeys.merchant_id) &&
                        renderHalfTxtInput(
                            "merchant_id",
                            `${CardPresentKeys.merchant_id}`,
                            card_present_merch_id,
                            setState,
                            false,
                            true,
                            undefined,
                            undefined,
                            shouldShowCloverDisconnectButton()
                        )}
                    {shouldRender(CardPresentKeys.refresh_token) && renderCloverRefreshToken()}
                    {shouldRender(CardPresentKeys.access_token) &&
                        renderHalfTxtInput(
                            "access_token",
                            `${CardPresentKeys.access_token}`,
                            card_present_access_token,
                            setState,
                            false,
                            true
                        )}
                    {shouldRender(CardPresentKeys.apiSecret) &&
                        renderHalfTxtInput(
                            "apiSecret",
                            `${CardPresentKeys.apiSecret}`,
                            card_present_api_secret,
                            setState,
                            false,
                            true
                        )}
                    {shouldRender(CardPresentKeys.authKey) &&
                        renderHalfTxtInput(
                            "authKey",
                            `${CardPresentKeys.authKey}`,
                            card_present_auth_key,
                            setState,
                            false,
                            true
                        )}
                    {shouldRender(CardPresentKeys.pos_id) &&
                        renderHalfTxtInput(
                            "pos_id",
                            `${CardPresentKeys.pos_id}`,
                            card_present_pos_id,
                            setState,
                            false,
                            true
                        )}
                    {shouldRender(CardPresentKeys.configCode) &&
                        renderHalfTxtInput(
                            "config_code",
                            `${CardPresentKeys.configCode}`,
                            card_present_config_code,
                            setState,
                            false,
                            true
                        )}
                    {shouldRender(CardPresentKeys.device_ids) &&
                        renderHalfMultiTextInput({
                            name: `${CardPresentKeys.device_ids}`,
                            label: "device_ids",
                            values: _.isArray(card_present_device_ids) ? card_present_device_ids : [],
                            inputValue: deviceId,
                            onChange: handleOnDeviceIdChange,
                            required: true,
                            onAdd: handleAddDeviceId,
                            onRemove: handleRemoveDeviceId,
                        })}
                    {shouldRender(CardPresentKeys.customer_ids) &&
                        renderHalfMultiNumberInput({
                            name: `${CardPresentKeys.customer_ids}`,
                            label: "customer_ids",
                            values: _.isArray(card_present_customer_ids) ? card_present_customer_ids : [],
                            inputValue: customerId,
                            onChange: handleOnCustomerIdChange,
                            required: false,
                            onAdd: handleAddCustomerId,
                            onRemove: handleRemoveCustomerId,
                        })}
                    <div className="card-present-switch-row">
                        {shouldRender(CardPresentKeys.is_debug) &&
                            renderHalfSwitch(
                                "debug",
                                `${CardPresentKeys.is_debug}`,
                                Number(card_present_is_debug),
                                switchChange
                            )}
                        {shouldRender(CardPresentKeys.enable_tips) &&
                            renderHalfSwitch(
                                "enable_tips",
                                `${CardPresentKeys.enable_tips}`,
                                isBool(card_present_enable_tips),
                                switchChange
                            )}
                    </div>
                </div>
            ) : null}
        </div>
    );
};

const mapStateToProps = (state: RootState): CardPresentStateProps => ({
    paymentOptions: _.get(state, "store.storePaymentOptions", {}),
    roles: _.get(state, "user.roles", []),
});

const mapDispatchToProps = {
    setPaymentState,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CardPresent);
