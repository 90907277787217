const StorePaymentOptions = `
    store_flg,                                             accept_credit_card,                    false,  4;
    store_flg,                                             accept_cash,                           false,  5;
    store_flg,                                             allow_pay_later,                       false,  34;
    store_flg,                                             allow_qr_checkout,                     false,  48;
    store_flg,                                             require_bill_address,                  false,  52;

    general_info.g_id,                                     store_id,                              false;

    payment_setting.paypal.mch_nm,                         paypal_merch_name,                     false;
    payment_setting.paypal.mch_phone,                      paypal_merch_phone,                    false;
    payment_setting.paypal.mch_login,                      paypal_merch_login,                    false;
    payment_setting.paypal.partner,                        paypal_partner,                        false;
    payment_setting.paypal.api_user,                       paypal_api_user,                       false;
    payment_setting.paypal.api_pass,                       paypal_api_pass,                       false;
    payment_setting.paypal.card_types,                     paypal_card_types,                     false;
    payment_setting.paypal.enabled,                        paypal_enabled,                        false;

    payment_setting.express.id,                            paypal_exp_id,                         false;
    payment_setting.express.secret,                        paypal_exp_secret,                     false;
    payment_setting.express.enabled,                       paypal_exp_enabled,                    false;

    payment_setting.stripe.mch_nm,                         stripe_merch_name,                     false;
    payment_setting.stripe.api_key,                        stripe_api_key,                        false;
    payment_setting.stripe.txn_type,                       stripe_x_type,                         false;
    payment_setting.stripe.card_types,                     stripe_card_types,                     false;
    payment_setting.stripe.enabled,                        stripe_enabled,                        false;

    payment_setting.wechat.app_id,                         wechat_app_id,                         false;
    payment_setting.wechat.credential,                     wechat_credential,                     false;
    payment_setting.wechat.merchant_id,                    wechat_merch_id,                       false;
    payment_setting.wechat.enabled,                        wechat_enabled,                        false;

    payment_setting.alipay[0].merchant_id,                 alipay_merch_id,                       false;
    payment_setting.alipay[0].credential,                  alipay_credential,                     false;
    payment_setting.alipay[0].is_from_alipay,              alipay_is_from,                        false;
    payment_setting.alipay[0].enabled,                     alipay_enabled,                        false;
    payment_setting.alipay[1].merchant_id,                 alipay_exc_merch_id,                   false;
    payment_setting.alipay[1].credential,                  alipay_exc_credential,                 false;
    payment_setting.alipay[1].is_from_alipay,              alipay_is_from_one,                    false;
    payment_setting.alipay[1].enabled,                     alipay_exc_enabled,                    false;

    payment_setting.unionpay.enabled,                      unionpay_enabled,                      false;
    payment_setting.unionpay.merchant_id,                  unionpay_merch_id,                     false;
    payment_setting.unionpay.credential,                   unionpay_credential,                   false;

    payment_setting.unionpayexpress.enabled,               unionpayexpress_enabled,               false;
    payment_setting.unionpayexpress.merchant_id,           unionpayexpress_merch_id,              false;
    payment_setting.unionpayexpress.credential,            unionpayexpress_credential,            false;

    payment_setting.braintree.merchant_id,                 braintree_merch_id,                    false;
    payment_setting.braintree.public_key,                  braintree_pub_key,                     false;
    payment_setting.braintree.private_key,                 braintree_pri_key,                     false;
    payment_setting.braintree.enabled,                     braintree_enabled,                     false;
    payment_setting.braintree.google_pay_merchant_id,      braintree_google_merchant_id,          false;
    store_flg,                                             braintree_allow_paypal,                false,          74;
    store_flg,                                             default_cc_gateway,                    false,          81;

    payment_setting.card_present.vendor,                   card_present_vendor,                   false;
    payment_setting.card_present.integration_type,         card_present_integration_type,         false;
    payment_setting.card_present.merchant_id,              card_present_merch_id,                 false;
    payment_setting.card_present.access_token,             card_present_access_token,             false;
    payment_setting.card_present.access_token_expiration,  card_present_access_token_expiration, false;
    payment_setting.card_present.refresh_token,            card_present_refresh_token,            false;
    payment_setting.card_present.refresh_token_expiration, card_present_refresh_token_expiration, false;
    payment_setting.card_present.pos_id,                   card_present_pos_id,                   false;
    payment_setting.card_present.enabled,                  card_present_enabled,                  false;
    payment_setting.card_present.device_ids,               card_present_device_ids,               false;
    payment_setting.card_present.customer_ids,             card_present_customer_ids,             false;
    payment_setting.card_present.is_debug,                 card_present_is_debug,                 false;
    payment_setting.card_present.app_id,                   card_present_app_id,                   false;
    payment_setting.card_present.pos_store_id,             card_present_pos_store_id,             false;
    payment_setting.card_present.enable_tips,              card_present_enable_tips,              false;
    payment_setting.card_present.api_secret,               card_present_api_secret,               false;
    payment_setting.card_present.auth_key,                 card_present_auth_key,                 false;
    payment_setting.card_present.config_code,              card_present_config_code,              false;

    payment_setting.clover.merchant_id,                    clover_merch_id,                       false;
    payment_setting.clover.access_token,                   clover_access_token,                   false;
    payment_setting.clover.api_key,                        clover_api_key,                        false;
    payment_setting.clover.enabled,                        clover_enabled,                        false;
    payment_setting.clover.is_debug,                       clover_is_debug,                       false;

    payment_setting.global_payment.app_id,                 global_payment_app_id,                 false;
    payment_setting.global_payment.auth_key,               global_payment_auth_key,               false;
    payment_setting.global_payment.api_key,                global_payment_api_key,                false;
    payment_setting.global_payment.api_secret,             global_payment_api_secret,             false;
    payment_setting.global_payment.enabled,                global_payment_enabled,                false;
    payment_setting.global_payment.is_debug,               global_payment_is_debug,               false;

    payment_setting.ppcp.enabled,                          ppcp_enabled,                          false;
    payment_setting.ppcp.merchant_id,                      ppcp_merchant_id,                      false;
    payment_setting.ppcp.txn_type,                         ppcp_txn_type,                         false;
    payment_setting.ppcp.mch_nm,                           ppcp_merch_name,                       false;
    payment_setting.ppcp.mch_phone,                        ppcp_merch_phone,                      false;
    payment_setting.ppcp.google_pay_enabled,               ppcp_payment_google_pay,               false;
    payment_setting.ppcp.apple_pay_enabled,                ppcp_payment_apple_pay,                false;
    payment_setting.ppcp.paypal_enabled,                   ppcp_payment_paypal,                   false;
    payment_setting.ppcp.partner_fee,                      ppcp_partner_fee,                      false;
    payment_setting.ppcp.mode,                             ppcp_mode,                             false;
    payment_setting.ppcp.credit_card_3ds_enabled,          ppcp_contingency_3d_secure,            false;
    payment_setting.ppcp.thirdparty_token,                 ppcp_thirdparty_token,                 false;
    payment_setting.ppcp.thirdparty_token_created_at,      ppcp_thirdparty_token_created_at,      false;
`;

export default StorePaymentOptions;
