import React from "react";
import { Select, Switch, Input, Button, Tag, InputNumber } from "antd";
import { FormattedMessage } from "react-intl";
import { isBool } from "../../../../../util/helper";

const emptyFunction = () => {
    // empty function
};

export interface SelectOption {
    value: string | number;
    label: string;
}

export const renderTop = (
    title: string,
    desc: string,
    name: string,
    value: string,
    onChange: any = emptyFunction,
    disabled?: boolean
): JSX.Element => (
    <React.Fragment>
        <div className="payment-title-container">
            <FormattedMessage id={title ?? ""} />
            <div className="enableSwitch">
                <div className="payment-subtitle mr-3 mb-0">
                    <FormattedMessage id="enable_this_pay_option" />
                </div>
                <Switch checked={isBool(value)} onChange={() => onChange(name, value)} disabled={disabled} />
            </div>
        </div>
        <div className="payment-desc">
            <FormattedMessage id={desc ?? ""} />
        </div>
    </React.Fragment>
);

/**
 * Renders a styled input box
 *
 * @param title - the label above the input box
 * @param name - name used to be sent in the on change function
 * @param value - value of the input
 * @param onChange - on input change
 * @param pass - use password input
 * @param req - append red asterisk after the title
 * @param maxLength - the maximum length of input
 * @param titleDescription - element placed after the label
 */
export const renderHalfTxtInput = (
    title: string,
    name: string | undefined,
    value: string,
    onChange: any = emptyFunction,
    pass?: boolean,
    req?: boolean,
    maxLength?: number,
    titleDescription?: React.ReactNode,
    readOnly?: boolean
): JSX.Element => (
    <div className="halfish-size d-flex flex-column justify-content-start align-items-start">
        <div className="payment-subtitle">
            <FormattedMessage id={title} />
            {req ? <span className="reqed">*</span> : ""}
            {titleDescription}
        </div>
        <Input
            type={pass ? "password" : "text"}
            className="custom-input-textfield halfish-size-input"
            value={value}
            onChange={(e) => onChange(name || title, e.target.value)}
            maxLength={maxLength}
            readOnly={readOnly}
        />
    </div>
);

export const renderHalfTxtSelec = (
    title: string,
    name: string,
    value: number,
    options: any[],
    onChange: any = emptyFunction,
    req?: boolean
): JSX.Element => (
    <div className="halfish-size d-flex flex-column justify-content-start align-items-start">
        <div className="payment-subtitle">
            <FormattedMessage id={title} />
            {req ? <span className="reqed">*</span> : ""}
        </div>
        <Select
            className="halfish-size-input"
            value={value}
            defaultValue={options?.[0].value}
            onChange={(e) => onChange(name, e)}
        >
            {options.map((option: SelectOption) => (
                <Select.Option key={option.value} value={option.value}>
                    {option.label}
                </Select.Option>
            ))}
        </Select>
    </div>
);

export const renderHalfMultiSel = (
    title: string,
    name: string,
    value: string,
    options: any[],
    onChange: any = emptyFunction,
    req?: boolean
): JSX.Element => (
    <div className="halfish-size d-flex flex-column justify-content-start align-items-start">
        <div className="payment-subtitle">
            <FormattedMessage id={title} />
            {req ? <span className="reqed">*</span> : ""}
        </div>
        <Select className="halfish-size-input-multi" value={value} mode="multiple" onChange={(e) => onChange(name, e)}>
            {options.map((option: SelectOption) => (
                <Select.Option key={option.value} value={option.value}>
                    {option.label}
                </Select.Option>
            ))}
        </Select>
    </div>
);

export const renderFullSwitch = (
    title: string,
    name: string,
    value: string | boolean,
    onChange: any = emptyFunction
): JSX.Element => (
    <div className="enableSwitch-100 mb-4">
        <div className="payment-subtitle mr-3 mb-0">
            <FormattedMessage id={title} />
        </div>
        <Switch checked={isBool(value)} onChange={() => onChange(name, value)} />
    </div>
);

export const renderHalfSwitch = (
    title: string,
    name: string,
    value: string | any,
    onChange: any = emptyFunction
): JSX.Element => (
    <div className="enableSwitch-50 mb-4">
        <div className="payment-subtitle mr-3 mb-0">
            <FormattedMessage id={title} />
        </div>
        <Switch checked={isBool(value)} onChange={() => onChange(name, value)} />
    </div>
);

export const renderHalfMultiTextInput = (opts: {
    name: string;
    label?: string;
    values?: string[];
    inputValue?: string;
    buttonLabel?: string;
    required?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onAdd: (event: React.MouseEvent<HTMLElement, MouseEvent>, value?: string) => void;
    onRemove: (index: number) => void;
}): JSX.Element => {
    const { name, label, values, inputValue, buttonLabel, required, onChange, onAdd, onRemove } = opts;

    return (
        <div className="halfish-size d-flex flex-column justify-content-start align-items-start">
            <div className="payment-subtitle">
                <FormattedMessage id={label} />
                {required ? <span className="reqed">*</span> : ""}
            </div>
            <div className="tag-container">
                {values?.map((value, index) => {
                    return (
                        <Tag
                            key={`${value}-${index}`}
                            className="input-tag"
                            closable={true}
                            onClose={() => onRemove(index)}
                        >
                            {value}
                        </Tag>
                    );
                })}
            </div>
            <Input.Group className="multi-input-group" compact>
                <Input
                    className="multi-text-input"
                    name={name}
                    onChange={onChange || emptyFunction}
                    value={inputValue}
                />
                <Button className="multi-text-button" type="primary" onClick={(e) => onAdd(e, inputValue)}>
                    <FormattedMessage id={buttonLabel || "add"} />
                </Button>
            </Input.Group>
        </div>
    );
};

export const renderHalfMultiNumberInput = (opts: {
    name: string;
    label?: string;
    values?: number[];
    inputValue?: number;
    buttonLabel?: string;
    required?: boolean;
    onChange?: (value: number) => void;
    onAdd: (event: React.MouseEvent<HTMLElement, MouseEvent>, value?: number) => void;
    onRemove: (index: number) => void;
}): JSX.Element => {
    const { name, label, values, inputValue, buttonLabel, required, onChange, onAdd, onRemove } = opts;

    return (
        <div className="halfish-size d-flex flex-column justify-content-start align-items-start">
            <div className="payment-subtitle">
                <FormattedMessage id={label} />
                {required ? <span className="reqed">*</span> : ""}
            </div>
            <div className="tag-container">
                {values?.map((value, index) => {
                    return (
                        <Tag
                            key={`${value}-${index}`}
                            className="input-tag"
                            closable={true}
                            onClose={() => onRemove(index)}
                        >
                            {value}
                        </Tag>
                    );
                })}
            </div>
            <Input.Group className="multi-input-group" compact>
                <InputNumber
                    className="multi-text-input"
                    name={name}
                    onChange={onChange || emptyFunction}
                    value={inputValue}
                />
                <Button className="multi-text-button" type="primary" onClick={(e) => onAdd(e, inputValue)}>
                    <FormattedMessage id={buttonLabel || "add"} />
                </Button>
            </Input.Group>
        </div>
    );
};

export const renderHalfNumberInput = (opts: {
    name: string;
    label?: string;
    value?: number;
    required?: boolean;
    onChange?: (value: number) => void;
    titleDescription?: React.ReactNode;
}): JSX.Element => {
    const { name, label, value, required, onChange, titleDescription } = opts;

    return (
        <div className="halfish-size d-flex flex-column justify-content-start align-items-start">
            <div className="payment-subtitle">
                <FormattedMessage id={label} />
                {required ? <span className="reqed">*</span> : ""}
                {titleDescription}
            </div>
            <InputNumber
                className="halfish-size-input"
                name={name}
                value={value}
                onChange={onChange || emptyFunction}
            />
        </div>
    );
};
