import React, { useEffect, useState } from "react";
import { Button, Switch, Tabs, Modal, Spin } from "antd";
import qs from "querystring";
import _ from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import { renderToolTip } from "./../../../../components/form";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import useLanguageSelect from "../../../../hooks/useLanguageSelect";
import Wrapper from "../../../../components/wrapper";
import { RootState } from "./../../../../app/reducer";
import {
    getStoreDetailsParts,
    updateStoreDetails,
    updateStoreDetailsParts,
    getOnBoardUser,
} from "./../../../../services/store";
import { setPaymentState } from "./../../../../slices/store";
import ObjectModel from "./../../../../util/models";
import StorePaymentOptions from "../../../../util/models/store-payment-options";
import { Prompt } from "react-router-dom";
import h from "./helper";
import config from "config";
import { isNum, isBool } from "../../../../util/helper";
import {
    PayPalProTab,
    PayPalExpTab,
    StripeTab,
    UnionPayDebitTab,
    UnionPayCreditTab,
    WeChatTab,
    AlipayTab,
    BrainTreeTab,
    CreditCardSetting,
    EMTTab,
    CardPresentTab,
    CloverTab,
    GlobalPaymentTab,
    PayPalCompletePaymentsTab,
} from "./_components/";
import "./payment.scss";
import { setIsOnboardLoaded } from "../../../../slices/ppcpOnboard";

const breadcrumb = {
    routes: [
        { path: "dashboard", breadcrumbName: "nav_dashboard" },
        { path: "/settings", breadcrumbName: "settings_overview" },
        { path: "/settings/paymentOptions", breadcrumbName: "payment_options" },
    ],
};

export enum PaymentTabs {
    PayPalPro = "paypal_account_setting",
    PayPalExp = "paypal_express_checkout",
    Stripe = "stripe_account_setting",
    UnionPayDebit = "unionpay_debit",
    UnionPayCredit = "unionpay_credit",
    Alipay = "alipay_account_setting",
    WeChat = "wechat_account_setting",
    BrainTree = "braintree_setting",
    EMT = "emt",
    CardPresent = "card_present_setting",
    Clover = "clover",
    GlobalPayment = "global_payment",
    PayPalCompletePayments = "ppcp_account_setting",
}

const PAYMENT_OPTIONS_TABS = [
    { key: PaymentTabs.BrainTree, tab: BrainTreeTab },
    { key: PaymentTabs.PayPalPro, tab: PayPalProTab },
    { key: PaymentTabs.PayPalCompletePayments, tab: PayPalCompletePaymentsTab },
    { key: PaymentTabs.PayPalExp, tab: PayPalExpTab },
    { key: PaymentTabs.Stripe, tab: StripeTab },
    { key: PaymentTabs.Alipay, tab: AlipayTab },
    { key: PaymentTabs.UnionPayDebit, tab: UnionPayDebitTab },
    { key: PaymentTabs.UnionPayCredit, tab: UnionPayCreditTab },
    { key: PaymentTabs.WeChat, tab: WeChatTab },
    { key: PaymentTabs.EMT, tab: EMTTab },
    { key: PaymentTabs.CardPresent, tab: CardPresentTab },
    { key: PaymentTabs.Clover, tab: CloverTab },
    { key: PaymentTabs.GlobalPayment, tab: GlobalPaymentTab },
];

const App = (): JSX.Element => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const history = useHistory();
    const location = useLocation();
    const { lan } = useLanguageSelect();
    const paymentOptions = useSelector((state: RootState) => state.store?.storePaymentOptions);
    const records = useSelector((state: RootState) => state.store?.records);
    const loading = useSelector((state: RootState) => state.store?.loading);
    const tabLoading = useSelector((state: RootState) => state.store?.tabLoading ?? false);
    const storeDetailsPartsLoading = useSelector((state: RootState) => state.store?.storeDetailsPartsLoading);
    const emtSettings = useSelector((state: RootState) => state.store?.emt_setting);
    const acceptCredit = _.get(paymentOptions, "accept_credit_card", "1");
    const tab = String(
        qs.parse(location.search)?.["?tab"] === PaymentTabs.PayPalPro && acceptCredit === "0"
            ? PaymentTabs.BrainTree
            : String(qs.parse(location.search)?.["?tab"] ?? PaymentTabs.BrainTree)
    );
    // initialize to be same as paymentOptions
    const [initialValues, setInitialValues] = useState(paymentOptions);
    const [initialEmtValues, setInitialEmtValues] = useState(emtSettings);
    const [hasChanges, setHasChanges] = useState(false);
    const [newTab, setNewTab] = useState("");
    const isOnboardLoaded = useSelector((state: RootState) => state.ppcpOnboard.isOnboardLoaded);

    useEffect(() => {
        // initial reset
        dispatch(getStoreDetailsParts({ page: "emt_setting" }));
    }, [dispatch]);

    useEffect(() => {
        if (!loading) {
            setInitialValues(paymentOptions);
        }
    }, [loading]);

    useEffect(() => {
        if (!storeDetailsPartsLoading) {
            setInitialEmtValues(emtSettings);
        }
    }, [storeDetailsPartsLoading]);

    useEffect(() => {
        setHasChanges(!_.isEqual(initialValues, paymentOptions) || !_.isEqual(initialEmtValues, emtSettings));
    }, [paymentOptions, emtSettings, initialValues, initialEmtValues]);

    useEffect(() => {
        if (newTab) {
            history.push("/settings/paymentOptions?tab=" + newTab);
            setNewTab("");
        }
    }, [newTab]);

    const switchChange = (value: any, name: string) =>
        dispatch(setPaymentState({ name, value: isNum(!isBool(value)) }));
    const clearForm = () => setInitialValues(paymentOptions);
    const onChangeTab = (tab: string) => {
        setNewTab(tab);
    };

    // []
    const saveChanges = async () => {
        if (
            !h.areRequiredPaymentOptionsFieldsFilled(
                paymentOptions,
                (hasMissingField: boolean, missingField: string) => {
                    if (hasMissingField) {
                        renderValidationModal(missingField);
                    }
                }
            )
        ) {
            if (paymentOptions.ppcp_enabled && paymentOptions.braintree_enabled) {
                renderValidationPaymentModal();
            } else if (
                paymentOptions.ppcp_enabled &&
                paymentOptions.ppcp_merch_phone &&
                !validatePhoneNumber(paymentOptions.ppcp_merch_phone)
            ) {
                renderPhoneValidationDataError();
            } else {
                if (isOnboardLoaded == 1) {
                    dispatch(setIsOnboardLoaded(0));
                    try {
                        const result: any = await dispatch(
                            getOnBoardUser({
                                ppcp_merchant_id: paymentOptions.ppcp_merchant_id,
                                ppcp_storeLink: config.H5_URL,
                            })
                        );
                        // Safely extract the properties, ensuring they are defined
                        const payments_receivable = result?.payments_receivable ?? false;
                        const primary_email_confirmed = result?.primary_email_confirmed ?? false;
                        const oauth_integrations = result?.oauth_integrations ?? [];

                        // Handle cases where properties might be missing
                        if (!payments_receivable) {
                            renderValidationPaymentModalPaymentsReceivable();
                        }

                        if (!primary_email_confirmed) {
                            renderValidationPaymentModalPrimaryEmailConfirmed();
                        }

                        if (oauth_integrations.length === 0) {
                            renderValidationPaymentModalOauthIntegrations();
                        }
                    } catch (error) {
                        renderValidationDataError();
                    }
                }

                clearForm();
                if (!_.isEqual(initialValues, paymentOptions)) {
                    dispatch(
                        updateStoreDetails(
                            new ObjectModel(StorePaymentOptions).convertToPostData(
                                paymentOptions,
                                records,
                                "payment_options"
                            )
                        )
                    );
                }
                if (!_.isEqual(initialEmtValues, emtSettings)) {
                    const emtSettingsClone = _.cloneDeep(emtSettings);
                    emtSettingsClone.emt_account = emtSettingsClone.emt_account?.trim();
                    dispatch(updateStoreDetailsParts({ emt_setting: emtSettingsClone }));
                }
            }
        }
    };

    const renderPayToggle = (tip: string, name: string, value: any, isEditable = true) => (
        <div className="pay-toggle-column d-flex">
            <div className="payment-switch-title">
                <FormattedMessage id={name ?? " "} />
                {renderToolTip(tip)}
            </div>
            <Switch
                disabled={!isEditable}
                checked={isBool(value)}
                onChange={() => (isEditable ? switchChange(value, name) : {})}
            />
        </div>
    );

    const validatePhoneNumber = (phoneNumber: any) => {
        const phoneRegex = /^(\+1\s?)?(\(?\d{3}\)?[-.\s]?)?\d{3}[-.\s]?\d{4}$/;
        return phoneRegex.test(phoneNumber);
    };

    const renderPaymentToggles = () => (
        <div className="payment-block">
            <div className="d-flex mb-3">
                {renderPayToggle("credit_card_tip", "accept_credit_card", paymentOptions.accept_credit_card)}
                {paymentOptions.accept_credit_card === "1"
                    ? renderPayToggle(
                          "require_bill_address_tip",
                          "require_bill_address",
                          paymentOptions.require_bill_address
                      )
                    : null}
                {renderPayToggle("cash_tip", "accept_cash", paymentOptions.accept_cash)}
                {renderPayToggle("pay_later_tip", "allow_pay_later", paymentOptions.allow_pay_later)}
            </div>
            <div className="d-flex">
                {renderPayToggle("virtual_terminal_tip", "allow_qr_checkout", paymentOptions.allow_qr_checkout, false)}
            </div>
        </div>
    );

    const renderValidationModal = (errorMessage: string) => {
        const req = intl.formatMessage({ id: "please_ensure_fill_all_required" });
        Modal.error({
            title: intl.formatMessage({ id: "missing_x" }, { x: intl.formatMessage({ id: errorMessage }) }),
            content: req,
        });
    };

    const renderValidationPaymentModal = () => {
        Modal.error({
            title: intl.formatMessage({ id: "payment_option_error" }),
            content: intl.formatMessage({ id: "payment_option_error_content" }),
        });
    };
    const renderValidationPaymentModalPaymentsReceivable = () => {
        Modal.error({
            title: intl.formatMessage({ id: "payment_validation_error" }),
            content: intl.formatMessage({ id: "payment_validation_PaymentsReceivable_error" }),
        });
    };
    const renderValidationPaymentModalPrimaryEmailConfirmed = () => {
        Modal.error({
            title: intl.formatMessage({ id: "payment_validation_error" }),
            content: intl.formatMessage({ id: "payment_validation_PrimaryEmailConfirmed_error" }),
        });
    };
    const renderValidationPaymentModalOauthIntegrations = () => {
        Modal.error({
            title: intl.formatMessage({ id: "payment_validation_error" }),
            content: intl.formatMessage({ id: "payment_validation_OauthIntegrations_error" }),
        });
    };

    const renderValidationDataError = () => {
        Modal.error({
            title: intl.formatMessage({ id: "payment_data_error" }),
            content: intl.formatMessage({ id: "payment_data_error_msg" }),
        });
    };

    const renderPhoneValidationDataError = () => {
        Modal.error({
            title: intl.formatMessage({ id: "merchant_number_error" }),
            content: intl.formatMessage({ id: "merchant_number_error_msg" }),
        });
    };

    const renderPageActions = () => (
        <div className="setting-actions floating-actions" style={{ marginTop: 14 }}>
            <Button size="large" disabled={!hasChanges} style={{ marginRight: 14 }} onClick={clearForm}>
                <FormattedMessage id="cancel" />
            </Button>
            <Button
                type="primary"
                size="large"
                onClick={() => saveChanges()}
                disabled={!hasChanges}
                className="save-button-handler"
            >
                <FormattedMessage id="save_changes" />
            </Button>
        </div>
    );

    const renderTabs = () => (
        <Spin spinning={tabLoading}>
            <Tabs
                type="card"
                activeKey={tab}
                defaultActiveKey={tab}
                onChange={onChangeTab}
                size="large"
                style={{ marginTop: 14 }}
            >
                {PAYMENT_OPTIONS_TABS.map((tab) => {
                    return paymentOptions.accept_credit_card !== "1" &&
                        (tab.key === PaymentTabs.PayPalPro || tab.key === PaymentTabs.Stripe) ? null : (
                        <Tabs.TabPane key={tab.key} tab={intl.formatMessage({ id: tab.key })}>
                            <tab.tab lan={lan} />
                        </Tabs.TabPane>
                    );
                })}
            </Tabs>
        </Spin>
    );

    const renderCreditCardSetting = () => (paymentOptions.accept_credit_card === "1" ? <CreditCardSetting /> : null);
    return (
        <>
            {!_.isEmpty(paymentOptions) ? (
                <Wrapper helmet={{ title: "payment_options" }} breadcrumb={breadcrumb}>
                    <Prompt when={hasChanges && !newTab} message={intl.formatMessage({ id: "unsaved_changes" })} />
                    {loading ? null : (
                        <div className="payment-options-page">
                            {renderPaymentToggles()}
                            {renderCreditCardSetting()}
                            {renderTabs()}
                            {renderPageActions()}
                        </div>
                    )}
                </Wrapper>
            ) : null}
        </>
    );
};

export default App;
